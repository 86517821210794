.main-page-cover {
  .carousel-photo-block {
    margin: 0 -33%;
    width: 170%;
    height: 100vh;
    position: fixed;

    .photo-view-background {
      background-position: center;
      background-size: cover;
      position: absolute;
      z-index: -1;
      opacity: 0.6;
      width: 100%;
      height: 100%;
    }

    .photo-view {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel.slide {
    width: 100%;
    height: 100%;
  }
}

.main-cover img {
  height: 600px;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @media (max-width: 400px) {
    height: 200px;
  }
}
.row-test {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 100%;
}

.main-recent-block {
  opacity: 0.6;
  background-image: url('../../../assets/NW_background.jpg');
}
